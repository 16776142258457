import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { SocialMedia, TopBar } from 'src/app/interfaces/topbar';
import { TopbarService } from 'src/app/services/topbar.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {

  topBars?: TopBar[]
  socialMedias?: SocialMedia[]

  constructor(private topBarService: TopbarService, private router: Router) { }

  ngOnInit(): void {
    this.fetchTopBars()
    this.fetchSocialMedias()
  }

  fetchTopBars(): void {
    this.topBarService.getTopBars().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.topBars = data
    })
  }

  fetchSocialMedias(): void {
    this.topBarService.getSocialMedias().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.socialMedias = data
    })
  }

}
