<div class="logisco-page-wrapper" id="logisco-page-wrapper">
    <div class="gdlr-core-page-builder-body">

        <div class="gdlr-core-pbf-wrapper " style="padding: 260px 0px 110px 0px;" id="gdlr-core-wrapper-1">
            <div class="gdlr-core-pbf-background-wrap">
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/slider-1-bg.jpg); background-size: cover ;background-position: center;"
                    data-parallax-speed="0"></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first" id=gdlr-core-column-1>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 190px 0px 30px 80px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js" *ngIf="homeSlide1">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                        style="padding-bottom: 35px ;">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 40px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #ffffff ;">
                                                {{ slideSection.title }}
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 20px ;font-style: normal ;color: #c5c5c5 ;margin-top: 20px ;">{{
                                            slideSection.content }}</span>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <a class="slideBtn" [routerLink]="slideSection.url">
                                            <span class=gdlr-core-content>Learn More</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30" data-skin="Homepage Enquiry Form"
                        id=gdlr-core-column-2>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="margin: 0px 20px 35px 125px;padding: 45px 25px 5px 25px;">
                            <div class=gdlr-core-pbf-background-wrap
                                style="background-color: #212121 ;opacity: 0.77 ;border-radius: 3px 3px 3px 3px;-moz-border-radius: 3px 3px 3px 3px;-webkit-border-radius: 3px 3px 3px 3px;">
                            </div>
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                        *ngIf="homeSlide2">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 22px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #ffffff ;">
                                                {{ slideSection2.title }}
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-style: normal ;color: #969696 ;">
                                            {{ slideSection2.content }}
                                        </span>
                                    </div>
                                </div>

                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                                        style="padding-bottom: 0px ;">
                                        <div role=form class=wpcf7 id=wpcf7-f6725-p2039-o1 lang=en-US dir=ltr>
                                            <div class=screen-reader-response></div>
                                            <form class="" (ngSubmit)="onSubmit()" [formGroup]="form">

                                                <div class="quform-elements">
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <input id="name" type="text" name="name" size="40"
                                                                class="input1" required placeholder="Name*" #name
                                                                formControlName="name">
                                                        </span>

                                                    </div>
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-email">
                                                            <input id="email" type="email" name="email" size="40"
                                                                class="input1" required placeholder="Email Address*"
                                                                #email formControlName="email">
                                                        </span>

                                                    </div>
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-message">
                                                            <textarea id="message" name="message" cols="40" rows="10"
                                                                class="input1" required placeholder="Message*" #message
                                                                formControlName="message"></textarea>
                                                        </span>

                                                    </div>
                                                    <div class="form-button-holder">
                                                        <button class="slideBtn" (click)="onSubmit()">
                                                            <span *ngIf="isLoading">
                                                                <i class="fa fa-spinner fa-spin"></i> Processing
                                                            </span>
                                                            <span *ngIf="!isLoading">Send</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper gdlr-new" id=gdlr-core-wrapper-2>
            <div class=gdlr-core-pbf-background-wrap></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class=" gdlr-core-pbf-wrapper-container-inner gdlr-core-item-mglr clearfix gdlr-inner-new">
                        <div class="gdlr-core-pbf-column gdlr-core-column-24 gdlr-core-column-first">
                            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style="padding: 50px 0px 20px 0px;" data-sync-height=map-height>
                                <div
                                    class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                    <div class=gdlr-core-pbf-element>
                                        <div
                                            class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                            <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                                style="border-width: 0px;">
                                                <img src="../../../assets/theme/upload/hp-1-map-1.jpg" alt=""
                                                    width="444" height="221" title="hp-1-map">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="gdlr-core-pbf-column gdlr-core-column-36" id=gdlr-core-column-3>
                            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style="padding: 90px 40px 0px 0px;" data-sync-height=map-height>
                                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"
                                    *ngIf="homeSlide3">
                                    <div class=gdlr-core-pbf-element>
                                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style="padding-bottom: 25px ;">
                                            <div class="gdlr-core-title-item-title-wrap ">
                                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style="font-size: 31px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #e53d34 ;">
                                                    {{ slideSection3.title }}<span
                                                        class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=gdlr-core-pbf-element>
                                        <div
                                            class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                            <div class=gdlr-core-text-box-item-content
                                                style="font-size: 17px ;text-transform: none ;color: #4f5d77 ;">
                                                <p>{{ slideSection3.content }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="margin: 0px 0px 0px 0px;padding: 115px 0px 120px 0px;"
            id=gdlr-core-wrapper-3>
            <div class=gdlr-core-pbf-background-wrap>
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/hp-map-bg-2.jpg) ;background-size: cover ;background-position: center ;"
                    data-parallax-speed=0.2></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">

                    <div class="gdlr-core-pbf-column gdlr-core-column-20" *ngFor="let item of section3Items">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 0px 10px 0px 10px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                                        style="padding-bottom: 30px;">
                                        <div class="gdlr-core-column-service-media gdlr-core-media-image"
                                            style="max-width: 61px ;margin-left: auto ;margin-right: auto ;">
                                            <img [src]="item.icon" alt width=100 height=118 title=icon-clock-n>
                                        </div>
                                        <div class=gdlr-core-column-service-content-wrapper>
                                            <div class=gdlr-core-column-service-title-wrap>
                                                <h3 class="gdlr-core-column-service-title gdlr-core-skin-title"
                                                    style="font-size: 23px ;font-weight: 700 ;text-transform: none ;">{{
                                                    item.title }}</h3>
                                            </div>
                                            <div class=gdlr-core-column-service-content
                                                style="font-size: 16px ;text-transform: none ;">
                                                <p>{{ item.content }} </p>
                                                <a class="gdlr-core-column-service-read-more gdlr-core-info-font"
                                                    [routerLink]="item.url" style="font-style: normal ;">
                                                    Get Started
                                                    <i class="fa fa-long-arrow-right"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 40px 0px 50px 0px;">
            <div class="gdlr-core-pbf-background-wrap"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
                    style="max-width: 1280px ;">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height=half-height
                            data-sync-height-center>
                            <div class="gdlr-core-pbf-background-wrap">
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    data-parallax-speed=0></div>
                            </div>

                            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style="padding: 35px 40px 55px 60px;" data-sync-height=half-height-2
                                data-sync-height-center>
                                <div class=gdlr-core-pbf-background-wrap
                                    style="box-shadow: 0 0 35px rgba(10, 10, 10,0.07); -moz-box-shadow: 0 0 35px rgba(10, 10, 10,0.07); -webkit-box-shadow: 0 0 35px rgba(10, 10, 10,0.07); ">
                                    <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                        style="background-image: url(../../../assets/theme/upload/hp-half-bg-3.jpg) ;background-size: cover ;background-position: center ;"
                                        data-parallax-speed=0></div>
                                </div>
                                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"
                                    data-gdlr-animation=fadeInLeft data-gdlr-animation-duration=600ms
                                    data-gdlr-animation-offset=0.8>
                                    <div class=gdlr-core-pbf-element>
                                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                            style="padding-bottom: 45px ;"><span
                                                class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                                style="font-size: 14px ;font-style: normal ;letter-spacing: 1px ;text-transform: uppercase ;color: #9a9a9a ;margin-bottom: 0px ;">we
                                                are an advanced company</span>
                                            <div class="gdlr-core-title-item-title-wrap ">
                                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style="font-size: 36px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #143369 ;">
                                                    Using High Technology<span
                                                        class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div class=gdlr-core-pbf-element>
                                        <div class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix "
                                            style="padding-bottom: 0px ;">
                                            <ul>
                                                <li class=" gdlr-core-skin-divider" style="margin-bottom: 50px ;"><span
                                                        class=gdlr-core-icon-list-image
                                                        style="margin-right: 38px ;"><img
                                                            src="../../../assets/theme/upload/building-logis.png" alt
                                                            width=47 height=43 title=building-logis></span>
                                                    <div class=gdlr-core-icon-list-content-wrap><span
                                                            class=gdlr-core-icon-list-content
                                                            style="color: #252525 ;font-size: 20px ;font-weight: 700 ;">We
                                                            Use AI In The Line </span><span
                                                            class=gdlr-core-icon-list-caption
                                                            style="color: #676767 ;font-size: 15px ;font-weight: 400 ;">That’s
                                                            why customers trust us — we have more than 80 years
                                                            of experience in the logistics and transportation.
                                                        </span></div>
                                                </li>
                                                <li class=" gdlr-core-skin-divider" style="margin-bottom: 50px ;"><span
                                                        class=gdlr-core-icon-list-image
                                                        style="margin-right: 38px ;"><img
                                                            src="../../../assets/theme/upload/home-logis.png" alt
                                                            width=44 height=36 title=home-logis></span>
                                                    <div class=gdlr-core-icon-list-content-wrap><span
                                                            class=gdlr-core-icon-list-content
                                                            style="color: #252525 ;font-size: 20px ;font-weight: 700 ;">Smart
                                                            Warehouse</span><span class=gdlr-core-icon-list-caption
                                                            style="color: #676767 ;font-size: 15px ;font-weight: 400 ;">That’s
                                                            why customers trust us — we have more than 80 years
                                                            of experience in the logistics and transportation.
                                                        </span></div>
                                                </li>
                                                <li class=" gdlr-core-skin-divider" style="margin-bottom: 50px ;"><span
                                                        class=gdlr-core-icon-list-image
                                                        style="margin-right: 38px ;"><img
                                                            src="../../../assets/theme/upload/gopgo-logis.png" alt
                                                            width=42 height=49 title=gopgo-logis></span>
                                                    <div class=gdlr-core-icon-list-content-wrap><span
                                                            class=gdlr-core-icon-list-content
                                                            style="color: #252525 ;font-size: 20px ;font-weight: 700 ;">100%
                                                            Accuracy</span><span class=gdlr-core-icon-list-caption
                                                            style="color: #676767 ;font-size: 15px ;font-weight: 400 ;">That’s
                                                            why customers trust us — we have more than 80 years
                                                            of experience in the logistics and transportation.
                                                        </span></div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="gdlr-core-pbf-column gdlr-core-column-30" id=gdlr-core-column-8>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 40px 60px 60px 60px;" data-sync-height=half-height>
                            <div class=gdlr-core-pbf-background-wrap>
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    data-parallax-speed=0></div>
                            </div>
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"
                                data-gdlr-animation=fadeInRight data-gdlr-animation-duration=600ms
                                data-gdlr-animation-offset=0.8>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                        style="padding-bottom: 25px ;"><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 14px ;font-style: normal ;letter-spacing: 1px ;text-transform: uppercase ;margin-bottom: 0px ;">Know
                                            us more</span>
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 40px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                                                More About Us<span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 6px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 21px ;text-transform: none ;">
                                            <p>We design and implement industry leading solutions for
                                                multinational companies. Approximately 73,000 employees.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 15px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;text-transform: none ;">
                                            <p>At XL Express & Logistics Ltd, we know experience matters. That’s why
                                                customers
                                                trust us — we have more than 80 years of experience in the
                                                logistics and transportation industry. For your air charter
                                                services, this translates to competence around the globe.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
                                            routerLink='/overview' id="gdlr-core-button-id-23256">
                                            <span class="gdlr-core-content">
                                                Know More
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 0px 0px 0px 0px;">
            <div class=gdlr-core-pbf-background-wrap></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                    <div class="gdlr-core-pbf-element">
                        <div class="gdlr-core-tab-featurev-item gdlr-core-js gdlr-core-item-pdb  gdlr-core-item-pdlr"
                            style="padding-bottom: 0px ;" id="gdlr-core-tab-featurev-2">
                            <div class="gdlr-core-tab-featurev-title-item-wrap clearfix gdlr-core-js"
                                data-sync-height=gdlr-core-tab-featurev-2-content
                                style="background: #0a0a0a; min-height: 650px;">
                                <div class=gdlr-core-tab-featurev-title-item-title-wrap>
                                    <div class=gdlr-core-tab-featurev-background-switch>
                                        <div class="gdlr-core-active " data-tab-id=1
                                            style="background-image: url(../../../assets/theme/upload/tab-about-1-1.jpg);">
                                        </div>
                                        <div class data-tab-id=2
                                            style="background-image: url(../../../assets/theme/upload/tab-about-2-1.jpg) ;">
                                        </div>
                                        <div class data-tab-id=3
                                            style="background-image: url(../../../assets/theme/upload/tab-about-3-1.jpg) ;">
                                        </div>
                                        <div class data-tab-id=4
                                            style="background-image: url(../../../assets/theme/upload/tab-about-4-1.jpg) ;">
                                        </div>
                                    </div>
                                    <div class=gdlr-core-tab-featurev-title-item-title-overlay
                                        style="background: linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, 0), rgba(10, 10, 10, 1));-moz-background: linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, 0), rgba(10, 10, 10, 1));-o-background: linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, 0), rgba(10, 10, 10, 1));-webkit-background: linear-gradient(rgba(10, 10, 10, 0), rgba(10, 10, 10, 0), rgba(10, 10, 10, 1));">
                                    </div>
                                    <div data-sync-height-offset></div>
                                    <div class=gdlr-core-tab-featurev-title-item-caption>
                                        Why choose us
                                    </div>
                                    <h3 class="gdlr-core-tab-featurev-title-item-title">
                                        What Makes Us Different
                                    </h3>
                                    <div class=gdlr-core-tab-featurev-title-divider></div>
                                </div>
                                <ul class=gdlr-core-tab-featurev-title-wrap>

                                    <li class="gdlr-core-tab-featurev-title gdlr-core-active " data-tab-id="1">
                                        <i class="fa fa-angle-right"></i>
                                        We are passionate about customers
                                    </li>

                                    <li class="gdlr-core-tab-featurev-title " data-tab-id=2><i
                                            class="fa fa-angle-right"></i>We are energetic and eager</li>
                                    <li class="gdlr-core-tab-featurev-title " data-tab-id=3><i
                                            class="fa fa-angle-right"></i>We are performance driven</li>
                                    <li class="gdlr-core-tab-featurev-title " data-tab-id=4><i
                                            class="fa fa-angle-right"></i>We are successful</li>
                                </ul>
                            </div>
                            <div class="gdlr-core-tab-featurev-item-content-wrap clearfix">
                                <div class="gdlr-core-tab-featurev-content-wrap gdlr-core-js  gdlr-core-active"
                                    data-tab-id="1" data-sync-height="gdlr-core-tab-featurev-2-content"
                                    style="background-image: url(../../../assets/theme/upload/tab-vertical-bg.jpg) ;"
                                    *ngIf="wu1">
                                    <div class=gdlr-core-tab-featurev-content-title-wrap>
                                        <div class="gldr-core-tab-featurev-content-title-image gdlr-core-media-image">
                                            <img src="../../../assets/theme/upload/tab-1-verticle-icon.png" alt width=59
                                                height=68 title=tab-1-verticle-icon>
                                        </div>
                                        <div class=gdlr-core-tab-featurev-content-title-caption>
                                            <span *ngIf="wu1">{{ wuData1.title }}</span>
                                        </div>
                                        <h3 class="gdlr-core-tab-featurev-content-title">
                                            <span *ngIf="wu1">{{ wuData1.title }}</span>
                                        </h3>
                                    </div>
                                    <div class=gdlr-core-tab-featurev-content>
                                        <span *ngIf="wu1">
                                            <span [innerHtml]="wuData1.content"></span>
                                        </span>
                                    </div>
                                </div>

                                <div class="gdlr-core-tab-featurev-content-wrap gdlr-core-js " data-tab-id="2"
                                    data-sync-height=gdlr-core-tab-featurev-2-content
                                    style="background-image: url(../../../assets/theme/upload/tab-vertical-bg.jpg) ;"
                                    *ngIf="wu1">
                                    <div class=gdlr-core-tab-featurev-content-title-wrap>
                                        <div class="gldr-core-tab-featurev-content-title-image gdlr-core-media-image">
                                            <img src="../../../assets/theme/upload/icon1.png" alt width=61 height=72
                                                title=icon1>
                                        </div>
                                        <div class=gdlr-core-tab-featurev-content-title-caption>
                                            <span *ngIf="wu2">{{ wuData2.title }}</span>
                                        </div>
                                        <h3 class="gdlr-core-tab-featurev-content-title">
                                            <span *ngIf="wu2">{{ wuData2.subtitle }}</span>
                                        </h3>
                                    </div>
                                    <div class=gdlr-core-tab-featurev-content>
                                        <span *ngIf="wu2">
                                            <span [innerHtml]="wuData2.content"></span>
                                        </span>
                                    </div>
                                </div>

                                <div class="gdlr-core-tab-featurev-content-wrap gdlr-core-js " data-tab-id=3
                                    data-sync-height=gdlr-core-tab-featurev-2-content
                                    style="background-image: url(../../../assets/theme/upload/tab-vertical-bg.jpg) ;">
                                    <div class=gdlr-core-tab-featurev-content-title-wrap>
                                        <div class="gldr-core-tab-featurev-content-title-image gdlr-core-media-image">
                                            <img src="../../../assets/theme/upload/icon2.png" alt width=76 height=61
                                                title=icon2>
                                        </div>
                                        <div class=gdlr-core-tab-featurev-content-title-caption>
                                            <span *ngIf="wu3">{{ wuData3.title }}</span>
                                        </div>
                                        <h3 class="gdlr-core-tab-featurev-content-title">
                                            <span *ngIf="wu2">{{ wuData3.subtitle }}</span>
                                        </h3>
                                    </div>
                                    <div class=gdlr-core-tab-featurev-content>
                                        <span *ngIf="wu3">
                                            <span [innerHtml]="wuData3.content"></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="gdlr-core-tab-featurev-content-wrap gdlr-core-js " data-tab-id=4
                                    data-sync-height=gdlr-core-tab-featurev-2-content
                                    style="background-image: url(../../../assets/theme/upload/tab-vertical-bg.jpg) ;">
                                    <div class=gdlr-core-tab-featurev-content-title-wrap>
                                        <div class="gldr-core-tab-featurev-content-title-image gdlr-core-media-image">
                                            <img src="../../../assets/theme/upload/icon3.png" alt width=64 height=67
                                                title=icon3>
                                        </div>
                                        <div class=gdlr-core-tab-featurev-content-title-caption>
                                            <span *ngIf="wu4">{{ wuData4.title }}</span>
                                        </div>
                                        <h3 class="gdlr-core-tab-featurev-content-title">
                                            <span *ngIf="wu4">{{ wuData4.subtitle }}</span>
                                        </h3>
                                    </div>
                                    <div class=gdlr-core-tab-featurev-content>
                                        <span *ngIf="wu4">
                                            <span [innerHtml]="wuData4.content"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>