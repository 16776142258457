<div class="logisco-page-title-wrap  logisco-style-custom logisco-center-align">
    <div class=logisco-header-transparent-substitute></div>
    <div class=logisco-page-title-overlay></div>
    <div class="logisco-page-title-container logisco-container">
        <div class="logisco-page-title-content logisco-item-pdlr">
            <h1 class="logisco-page-title">{{ title }}</h1>
            <div class=logisco-page-caption> {{ subtitle }} </div>
        </div>
    </div>
</div>

<div class=logisco-page-wrapper id=logisco-page-wrapper>
    <div class=gdlr-core-page-builder-body>
        <div class="gdlr-core-pbf-wrapper ">
            <div class=gdlr-core-pbf-background-wrap></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">

                    <div class="gdlr-core-pbf-column gdlr-core-column-20" *ngFor="let service of services">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align"
                                        style="padding-bottom: 25px ;">
                                        <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-round"
                                            style="border-width: 0px;">
                                            <a [routerLink]="service.url">
                                                <img [src]="service.icon" alt width=700 height=430
                                                    title=shutterstock_350092247><span
                                                    class="gdlr-core-image-overlay "><i
                                                        class="gdlr-core-image-overlay-icon gdlr-core-size-28 fa fa-external-link"></i></span></a>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                        style="padding-bottom: 40px ;" id=gdlr-core-title-item-id-38608>
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 22px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #aa1e25 ;">
                                                <a [routerLink]="service.url">{{service.title}}</a>
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 15px ;font-weight: 600 ;font-style: normal ;color: #acacac ;margin-top: 5px ;">{{
                                            service.subtitle }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 110px 0px 20px 0px;" id=gdlr-core-wrapper-1>
            <div class=gdlr-core-pbf-background-wrap>
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/about-1-globe-bg-1.jpg) ;background-size: cover ;background-position: top center ;"
                    data-parallax-speed=0.15></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container" style="min-height: 550px;">
                    <div class=gdlr-core-pbf-element>
                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                            style="padding-bottom: 55px ;" id=gdlr-core-title-item-2>
                            <div class="gdlr-core-title-item-title-wrap ">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                    style="font-size: 32px ;letter-spacing: 0px ;text-transform: none ;color: #aa1e25 ;">
                                    Mission
                                    <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 0px 20px 0px 0px;" data-sync-height="height logis1">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                                        <ul>

                                            <li class=" gdlr-core-skin-divider" style="margin-bottom: 50px ;"
                                                *ngFor="let item of missionItems">
                                                <span class=gdlr-core-icon-list-image style="margin-right: 38px ;">
                                                    <img [src]="item.icon" alt width=50 height=59 title="clock-icon">
                                                </span>
                                                <div class="gdlr-core-icon-list-content-wrap">
                                                    <span class="gdlr-core-icon-list-content"
                                                        style="color: #252525 ;font-size: 20px ;font-weight: 700 ;">{{
                                                        item.title }}</span>
                                                    <span class=gdlr-core-icon-list-caption
                                                        style="color: #4f5d77 ;font-size: 16px ;font-weight: 400 ;">
                                                        {{ item.content }}
                                                    </span>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height="height logis1">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 22px ;font-weight: 400 ;text-transform: none ;color: #090909 ;">
                                            <span *ngIf="mission">
                                                <span [innerHtml]="missionSection.title"></span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;font-weight: 400 ;text-transform: none ;color: #656565 ;">
                                            <span *ngIf="mission">
                                                <span [innerHtml]="missionSection.content"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>