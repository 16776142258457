<div class="logisco-top-bar">
    <div class="logisco-top-bar-background"></div>
    <div class="logisco-top-bar-container logisco-top-bar-full">
        <div class="logisco-top-bar-container-inner clearfix">
            <div class="logisco-top-bar-left logisco-item-pdlr">
                <div class="gdlr-core-dropdown-tab gdlr-core-js clearfix">
                    <div class=gdlr-core-dropdown-tab-content-wrap>

                        <div class="gdlr-core-dropdown-tab-content gdlr-core-active" data-index="0">

                            <span *ngFor="let topBar of topBars">
                                <i [ngClass]="topBar.icon" [attr.data-id]="'i_fdfd_' + topBar.refNumber"></i>
                                &nbsp;
                                <span *ngIf="topBar.isMail == true">
                                    <a [href]="'mailto:' +topBar.url">{{ topBar.title }}</a>
                                </span>
                                <span *ngIf="topBar.isMail== false">
                                    <a [href]="'tel:' +topBar.url">{{ topBar.title }}</a>
                                </span>
                                &nbsp; &nbsp;
                            </span>

                        </div>
                        <div class="gdlr-core-dropdown-tab-content " data-index=1>

                            <span *ngFor="let topBar of topBars">
                                <i [ngClass]="topBar.icon" [attr.data-id]="'i_fdfd_' + topBar.refNumber"></i>
                                &nbsp;
                                <span *ngIf="topBar.isMail == true">
                                    <a [href]="'mailto:' +topBar.url">{{ topBar.title }}</a>
                                </span>
                                <span *ngIf="topBar.isMail== false">
                                    <a [href]="'tel:' +topBar.url">{{ topBar.title }}</a>
                                </span>
                                &nbsp; &nbsp;
                            </span>

                        </div>
                    </div>
                </div>
            </div>

            <a class="logisco-top-bar-right-button" routerLink="/tracker">
                <i class="fa fa-map-marker mr-10"></i> Track Package
            </a>

            <div class="logisco-top-bar-right logisco-item-pdlr">
                <div class="logisco-top-bar-right-social">

                    <a *ngFor="let socialMedia of socialMedias" [href]="socialMedia.url" target="_blank"
                        class="logisco-top-bar-social-icon">
                        <i [ngClass]="socialMedia.icon"></i>
                    </a>

                </div>
            </div>

        </div>
    </div>
</div>