import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Footer } from '../interfaces/footer';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  private footerPath = '/footeer'
  footerRef: AngularFirestoreCollection<Footer>

  collection = this.db.collection('footeer')

  usefulLinks = 'usefulLinks'
  companies = 'companies'

  constructor(private db: AngularFirestore) {
    this.footerRef = db.collection(this.footerPath)
  }

  getFooter(): AngularFirestoreCollection<Footer> {
    return this.footerRef
  }

  // Get all useful links
  getUsefulLinks(uid: string) {
    return this.collection.doc(uid).collection(this.usefulLinks).snapshotChanges();
  }

  // Get all companies links
  getCompanies(uid: string) {
    return this.collection.doc(uid).collection(this.companies).snapshotChanges();
  }

}
