<footer>
    <div class="logisco-footer-wrapper ">
        <div class="logisco-footer-container logisco-container clearfix">
            <div class="logisco-footer-column logisco-item-pdlr logisco-column-15">
                <div id=text-1 class="widget widget_text logisco-widget">
                    <div class=textwidget>
                        <p *ngFor="let item of footer">
                            <img [src]="item.logo" alt="XL Express" title="Logo" width="200">
                            <br />
                            <span class=gdlr-core-space-shortcode style="margin-top: -27px ;"></span>
                            <br /> {{ item.intro }}
                        </p>

                        <div class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-none-align"
                            style="padding-bottom: 0px ;">

                            <a *ngFor="let socialMedia of socialMedias" [href]="socialMedia.url" target="_blank"
                                class="gdlr-core-social-network-icon" style="font-size: 16px ;color: #fff ;">
                                <i [ngClass]="socialMedia.icon"></i>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
            <div class="logisco-footer-column logisco-item-pdlr logisco-column-15">
                <div id=text-5 class="widget widget_text logisco-widget">
                    <h3 class="logisco-widget-title">Contact Info</h3><span class="clear"></span>
                    <div class="textwidget" *ngFor="let item of footer">
                        <p>{{ item.contacts.address }}</p>
                        <p>
                            <span style="color: #fff;">{{ item.contacts.phoneNumber }}</span><br />
                            <span style="color: #e53d34;">{{ item.contacts.email }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="logisco-footer-column logisco-item-pdlr logisco-column-15">
                <div id="gdlr-core-custom-menu-widget-2"
                    class="widget widget_gdlr-core-custom-menu-widget logisco-widget">
                    <h3 class="logisco-widget-title">Useful Links</h3><span class="clear"></span>
                    <div class="menu-useful-links-container">
                        <ul id="menu-useful-links" class="gdlr-core-custom-menu-widget">
                            <li class="menu-item" *ngFor="let usefulLink of usefulLinks">
                                <a [routerLink]="usefulLink.url">{{ usefulLink.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="logisco-footer-column logisco-item-pdlr logisco-column-15">
                <div id="gdlr-core-custom-menu-widget-2"
                    class="widget widget_gdlr-core-custom-menu-widget logisco-widget">
                    <h3 class="logisco-widget-title">Companies</h3><span class="clear"></span>
                    <div class="menu-useful-links-container">
                        <ul id="menu-useful-links" class="gdlr-core-custom-menu-widget">
                            <li class="menu-item" *ngFor="let company of companies">
                                <a [routerLink]="company.url">{{ company.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=logisco-copyright-wrapper>
        <div class="logisco-copyright-container logisco-container clearfix">
            <div class="logisco-copyright-left logisco-item-pdlr">
                Copyright © {{ year }} XL Express & Logistics Ltd, All Right Reserved
            </div>
            <div class="logisco-copyright-right logisco-item-pdlr">
                Site By:
                <a href="https://etechds.com.ng" target="_blank" style="margin-left:21px;">Etech Data Services</a>
            </div>
        </div>
    </div>
</footer>