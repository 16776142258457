<div class="logisco-page-title-wrap  logisco-style-custom logisco-center-align"
    style="background-image: url(../../../assets/theme/upload/service-title-bg.jpg) ;">
    <div class=logisco-header-transparent-substitute></div>
    <div class=logisco-page-title-overlay></div>
    <div class="logisco-page-title-container logisco-container">
        <div class="logisco-page-title-content logisco-item-pdlr">
            <h1 class="logisco-page-title">{{ title }}</h1>
            <div class=logisco-page-caption>{{ subtitle }}</div>
        </div>
    </div>
</div>

<div class="logisco-page-wrapper" id="logisco-page-wrapper">
    <div class="gdlr-core-page-builder-body">
        <div class="gdlr-core-pbf-wrapper "
            style="padding: 4px 0px 26px 0px;border-width: 0px 0px 1px 0px;border-color: #ededed ;border-style: solid ;">
            <div class="gdlr-core-pbf-background-wrap"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-element">
                        <div class="gdlr-core-breadcrumbs-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                            style="padding-bottom: 0px ;">
                            <span property=itemListElement typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Go to Home" href="/" class="home">
                                    <span property="name">Home</span>
                                </a>
                                <meta property="position" content="1">
                            </span>&#183;
                            <span property="itemListElement" typeof="ListItem">
                                <a property="item" typeof="WebPage" title="Go to Services." routerLink="/services"
                                    class="post post-page">
                                    <span property="name">Services</span>
                                </a>
                                <meta property="position" content="2">
                            </span>&#183;
                            <span class="post post-page current-item"><span *ngIf="serviceLoaded">{{ subtitle
                                    }}</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-sidebar-wrapper ">
            <div
                class="gdlr-core-pbf-sidebar-container gdlr-core-line-height-0 clearfix gdlr-core-js gdlr-core-container">
                <div class="gdlr-core-pbf-sidebar-content  gdlr-core-column-45 gdlr-core-pbf-sidebar-padding gdlr-core-line-height gdlr-core-column-extend-right"
                    style="padding: 50px 0px 30px 10px;">
                    <div class=gdlr-core-pbf-sidebar-content-inner>
                        <div class=gdlr-core-pbf-element>
                            <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style="padding-bottom: 26px ;" id=gdlr-core-title-item-1>
                                <div class="gdlr-core-title-item-title-wrap ">
                                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                        style="font-size: 28px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #aa1e25  ;">
                                        <span *ngIf="serviceLoaded">
                                            {{ subtitle }}</span>
                                        <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class=gdlr-core-pbf-element>
                            <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                style="padding-bottom: 20px ;">
                                <div class=gdlr-core-text-box-item-content
                                    style="font-size: 20px ;font-weight: 400 ;text-transform: none;">
                                    <span *ngIf="serviceLoaded">
                                        {{ service.content }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class=gdlr-core-pbf-element>
                            <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                style="padding-bottom: 25px ;">
                                <div class="gdlr-core-title-item-title-wrap ">
                                    <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                        style="font-size: 28px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #aa1e25 ;">
                                        Benefits<span
                                            class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div class=gdlr-core-pbf-element>
                            <div class="gdlr-core-accordion-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-accordion-style-icon"
                                style="padding-bottom: 40px ;">

                                <div *ngFor="let benefit of benefits"
                                    class="gdlr-core-accordion-item-tab clearfix  gdlr-core-active">
                                    <div class="gdlr-core-accordion-item-icon gdlr-core-js gdlr-core-skin-icon ">
                                    </div>
                                    <div class=gdlr-core-accordion-item-content-wrapper>
                                        <h4 class="gdlr-core-accordion-item-title gdlr-core-js "
                                            style="font-size: 15px ;font-weight: 700 ;letter-spacing: 1px ;text-transform: uppercase ;">
                                            {{ benefit.title }}
                                        </h4>
                                        <div class=gdlr-core-accordion-item-content>
                                            <span [innerHtml]="benefit.content"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div class="gdlr-core-pbf-sidebar-left gdlr-core-column-extend-left  logisco-sidebar-area gdlr-core-column-15 gdlr-core-pbf-sidebar-padding  gdlr-core-line-height"
                    style="padding: 50px 0px 30px 0px;">
                    <div class="gdlr-core-sidebar-item gdlr-core-item-pdlr">
                        <div id=gdlr-core-custom-menu-widget-3
                            class="widget widget_gdlr-core-custom-menu-widget logisco-widget">
                            <h3 class="logisco-widget-title">Our Services</h3><span class=clear></span>
                            <div class=menu-services-container>
                                <ul id=menu-services class="gdlr-core-custom-menu-widget gdlr-core-menu-style-box">

                                    <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-6652"
                                        *ngFor="let service of services">
                                        <a [routerLink]="'/service/' + service.url">{{ service.title }}</a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                        <div id=text-7 class="widget widget_text logisco-widget">
                            <div class=textwidget>
                                <span class=gdlr-core-space-shortcode style="margin-top: 70px ;"></span>
                                <div class="gdlr-core-widget-box-shortcode "
                                    style="box-shadow: 0 0 20px rgba(0, 0, 0,0.1); -moz-box-shadow: 0 0 20px rgba(0, 0, 0,0.1); -webkit-box-shadow: 0 0 20px rgba(0, 0, 0,0.1); padding: 20px 37px 20px 37px;">
                                    <div class=gdlr-core-widget-box-shortcode-content>
                                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top"
                                            style="padding-bottom: 20px ;">
                                            <div class="gdlr-core-title-item-title-wrap ">
                                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                    style="font-size: 19px ;font-weight: 700 ;text-transform: none ;">
                                                    Need Help?<span
                                                        class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                                </h3>
                                            </div>
                                        </div>Please feel free to contact us. We will get back to you with
                                        1-2 business days. Or just call us now
                                        <p><i class="fa fa-envelope-open"
                                                style="font-size: 14px ;color: #2a2a2a ;margin-left: 2px ;margin-right: 11px ;"></i><a
                                                href="mailto:info@xlexpressandlogistics.com  "
                                                style="font-size: 14px;">info@xlexpressandlogistics.com</a><span
                                                class=gdlr-core-space-shortcode style="margin-top: 12px ;"></span><i
                                                class="fa fa-phone"
                                                style="font-size: 14px ;color: #2a2a2a ;margin-left: 2px ;margin-right: 11px ;"></i>+234
                                            (0) 803 549 6302
                                        </p>
                                    </div>
                                </div>
                                <a class="gdlr-core-button gdlr-core-button-shortcode  gdlr-core-button-gradient gdlr-core-button-no-border gdlr-core-button-full-width"
                                    routerLink='/contact-us'
                                    style="font-size: 16px ;font-weight: 700 ;letter-spacing: 0px ;color: #aa1e25 ;padding: 19px 57px 22px 57px;text-transform: none ;border-radius: 0px;-moz-border-radius: 0px;-webkit-border-radius: 0px;position: relative ;background: #f1f1f1 ;"><i
                                        class="gdlr-core-pos-left fa fa-comments"></i><span class=gdlr-core-content>Get
                                        Quote</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gdlr-core-pbf-wrapper " style="padding: 0px 0px 0px 0px;" id=gdlr-core-wrapper-1>
            <div class=gdlr-core-pbf-background-wrap style="background-color: #aa1e25 ;">
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="opacity: 0.2 ;background-image: url(../../../assets/theme/upload/illia-cherednychenko-178496-unsplash.jpg) ;background-size: cover ;background-position: center ;"
                    data-parallax-speed=0.2></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first" id=gdlr-core-column-1>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 190px 0px 50px 0px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                        style="padding-bottom: 25px ;">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 35px ;letter-spacing: 0px ;text-transform: none ;color: #ffffff ;">
                                                Video Tour For This Service<span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 21px ;font-style: normal ;color: #e53d34 ;margin-top: 7px ;">Take
                                            a tour and see the greatest logistic company in USA </span>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 0px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;font-weight: 400 ;letter-spacing: 0px ;text-transform: none ;color: #aab6ca ;">
                                            <p>At Logisco, we know experience matters. That’s why customers
                                                trust us — we have more than 60 years of experience in the
                                                logistics and transportation industry. For your services,
                                                this translates to competence around the globe.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30" id=gdlr-core-column-2>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="margin: 110px 20px 110px 20px;padding: 135px 0px 135px 0px;">
                            <div class=gdlr-core-pbf-background-wrap style="background-color: #181818 ;">
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    style="background-image: url(../../../assets/theme/upload/shutterstock_171404945.jpg) ;opacity: 0.4 ;background-size: cover ;background-position: center ;"
                                    data-parallax-speed=0></div>
                            </div>
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align"
                                        style="padding-bottom: 0px ;">
                                        <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                            style="border-width: 0px;">
                                            <a class="gdlr-core-lightgallery gdlr-core-js "
                                                href="https://www.youtube.com/watch?v=wtPB-VNNK2Q"><img
                                                    src="../../../assets/theme/upload/play1-icon.png" alt width=82
                                                    height=82 title=play1-icon></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="gdlr-core-pbf-wrapper " style="padding: 90px 0px 40px 0px;">
            <div class=gdlr-core-pbf-background-wrap></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height="height 33">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-tab-item gdlr-core-js gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-tab-style1-horizontal gdlr-core-item-pdlr">
                                        <div class="gdlr-core-tab-item-title-wrap clearfix gdlr-core-title-font">
                                            <div class="gdlr-core-tab-item-title  gdlr-core-active" data-tab-id=1>
                                                overview</div>
                                            <div class="gdlr-core-tab-item-title " data-tab-id=2>Security
                                            </div>
                                            <div class="gdlr-core-tab-item-title " data-tab-id=3>Management
                                            </div>
                                        </div>
                                        <div class="gdlr-core-tab-item-content-wrap clearfix">
                                            <div class="gdlr-core-tab-item-content  gdlr-core-active" data-tab-id=1>
                                                <p><span style="color: #aa1e25; font-size: 21px; font-weight: bold;">Why
                                                        Choose Our Service</span> <span class=gdlr-core-space-shortcode
                                                        style="margin-top: 20px ;"></span> Using Logisco is
                                                    easy. We deliver packages up to 50kg, anywhere in USA
                                                    &#038; Europe. All you must do is place a single order
                                                    with your local Logisco office. With just one booking,
                                                    we will collect your shipment​​​​​​​ in a single vehicle
                                                    at your location, and track it with a single tracking
                                                    system. Following your package from start to finish.
                                                    Partnership is an over-used word, but it does go some
                                                    way to describing our customer-focused services. We
                                                    strive to bring much more to the relationship than
                                                    simply a means of reaching your customers across the
                                                    world.solutions that meet the needs of today and enable
                                                    the opportunities of tomorrow.</p>
                                            </div>
                                            <div class="gdlr-core-tab-item-content " data-tab-id=2>
                                                <p><span
                                                        style="color: #aa1e25; font-size: 21px; font-weight: bold;">Security</span>
                                                    <span class=gdlr-core-space-shortcode
                                                        style="margin-top: 20px ;"></span> Using Logisco is
                                                    easy. We deliver packages up to 50kg, anywhere in USA
                                                    &#038; Europe. All you must do is place a single order
                                                    with your local Logisco office. With just one booking,
                                                    we will collect your shipment​​​​​​​ in a single vehicle
                                                    at your location, and track it with a single tracking
                                                    system. Following your package from start to finish.
                                                    Partnership is an over-used word, but it does go some
                                                    way to describing our customer-focused services. We
                                                    strive to bring much more to the relationship than
                                                    simply a means of reaching your customers across the
                                                    world.solutions that meet the needs of today and enable
                                                    the opportunities of tomorrow.
                                                </p>
                                            </div>
                                            <div class="gdlr-core-tab-item-content " data-tab-id=3>
                                                <p><span
                                                        style="color: #aa1e25; font-size: 21px; font-weight: bold;">Management</span>
                                                    <span class=gdlr-core-space-shortcode
                                                        style="margin-top: 20px ;"></span> Using Logisco is
                                                    easy. We deliver packages up to 50kg, anywhere in USA
                                                    &#038; Europe. All you must do is place a single order
                                                    with your local Logisco office. With just one booking,
                                                    we will collect your shipment​​​​​​​ in a single vehicle
                                                    at your location, and track it with a single tracking
                                                    system. Following your package from start to finish.
                                                    Partnership is an over-used word, but it does go some
                                                    way to describing our customer-focused services. We
                                                    strive to bring much more to the relationship than
                                                    simply a means of reaching your customers across the
                                                    world.solutions that meet the needs of today and enable
                                                    the opportunities of tomorrow.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 20px 0px 0px 0px;" data-sync-height="height 33">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                                        <ul>
                                            <li class=" gdlr-core-skin-divider" style="margin-bottom: 35px ;"><span
                                                    class=gdlr-core-icon-list-image style="margin-right: 28px ;"><img
                                                        src="../../../assets/theme/upload/building-logis.png" alt
                                                        width=47 height=43 title=building-logis></span>
                                                <div class=gdlr-core-icon-list-content-wrap><span
                                                        class=gdlr-core-icon-list-content
                                                        style="color: #171717 ;font-size: 21px ;font-weight: 700 ;letter-spacing: 0px ;">We
                                                        Use AI In The Line </span><span
                                                        class=gdlr-core-icon-list-caption
                                                        style="color: #676767 ;letter-spacing: 0px ;">That’s
                                                        why customers trust us — we have more than 80 years
                                                        of experience in the logistics and transportation
                                                        industry. </span></div>
                                            </li>
                                            <li class=" gdlr-core-skin-divider" style="margin-bottom: 35px ;"><span
                                                    class=gdlr-core-icon-list-image style="margin-right: 28px ;"><img
                                                        src="../../../assets/theme/upload/home-logis.png" alt width=44
                                                        height=36 title=home-logis></span>
                                                <div class=gdlr-core-icon-list-content-wrap><span
                                                        class=gdlr-core-icon-list-content
                                                        style="color: #171717 ;font-size: 21px ;font-weight: 700 ;letter-spacing: 0px ;">Smart
                                                        Warehouse</span><span class=gdlr-core-icon-list-caption
                                                        style="color: #676767 ;letter-spacing: 0px ;">That’s
                                                        why customers trust us — we have more than 80 years
                                                        of experience in the logistics and transportation
                                                        industry. </span></div>
                                            </li>
                                            <li class=" gdlr-core-skin-divider" style="margin-bottom: 35px ;"><span
                                                    class=gdlr-core-icon-list-image style="margin-right: 28px ;"><img
                                                        src="../../../assets/theme/upload/gopgo-logis.png" alt width=42
                                                        height=49 title=gopgo-logis></span>
                                                <div class=gdlr-core-icon-list-content-wrap><span
                                                        class=gdlr-core-icon-list-content
                                                        style="color: #171717 ;font-size: 21px ;font-weight: 700 ;letter-spacing: 0px ;">100%
                                                        Accuracy</span><span class=gdlr-core-icon-list-caption
                                                        style="color: #676767 ;letter-spacing: 0px ;">That’s
                                                        why customers trust us — we have more than 80 years
                                                        of experience in the logistics and transportation
                                                        industry. </span></div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gdlr-core-pbf-wrapper " style="padding: 57px 0px 21px 0px;" data-skin="logisco call to action1">
            <div class=gdlr-core-pbf-background-wrap style="background-color: #e53d34 ;"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-48 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 11px 0px 0px 0px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 27px ;font-weight: 400 ;letter-spacing: 0px ;text-transform: none ;color: #ffffff ;">
                                                We can be your great business partner and help you growth
                                                strongly.<span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-12">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-center-align">
                                        <a class="gdlr-core-button  gdlr-core-button-gradient gdlr-core-button-no-border"
                                            href=# id=gdlr-core-button-id-37054><i
                                                class="gdlr-core-pos-left fa fa-comments"
                                                style="font-size: 16px ;color: #ffffff ;"></i><span
                                                class=gdlr-core-content>Get A Quote Now</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>