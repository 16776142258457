import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Footer } from 'src/app/interfaces/footer';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrls: ['./mobile-header.component.css']
})
export class MobileHeaderComponent implements OnInit {

  footer?: Footer[]
  menus = [
    {
      title: 'Home',
      url: '/',
      hasKids: false,
      children: []
    },
    {
      title: 'About Us',
      url: '/about-us',
      hasKids: true,
      children: [
        {
          title: 'Overview',
          url: '/overview'
        },
        {
          title: 'Management Team',
          url: '/management-team'
        }
      ]
    },
    {
      title: 'Services',
      url: '/services',
      hasKids: true,
      children: [
        {
          title: 'Mail Room Management',
          url: '/mail-room-management'
        },
        {
          title: 'Express Delivery and Courier Services',
          url: '/express-delivery-courier-services'
        },
        {
          title: 'Cargo Clearing & amp; Forwarding',
          url: '/cargo-clearing-forwarding'
        },
        {
          title: 'Bulk Mail Deliveries',
          url: '/bulk-mail-deliveries'
        },
        {
          title: 'Haulage',
          url: '/haulage'
        }
      ]
    },
    {
      title: 'Contact Us',
      url: '/contact-us',
      hasKids: false,
      children: []
    },
    {
      title: 'Get Quote',
      url: '/get-quote',
      hasKids: false,
      children: []
    }
  ]

  constructor(private footerService: FooterService, private router: Router) { }

  ngOnInit(): void {
    this.fetchFooter()
  }

  fetchFooter(): void {
    this.footerService.getFooter().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.footer = data
    })
  }

}
