import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HomeItems } from 'src/app/interfaces/home';
import { Service } from 'src/app/interfaces/service';
import { OverviewService } from 'src/app/services/overview.service';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {


  title: string = 'Our Services'
  subtitle: string = 'We handle your parcel delivery with the professionalism '

  about: boolean = false
  aboutSection: any

  mission: boolean = false
  missionSection: any
  missionId = 'm1'
  missionItems?: any

  whyUs: boolean = false
  whyUsSection: any

  services?: Service[]

  constructor(private titleService: Title, private overviewService: OverviewService, private serviceService: ServiceService, private router: Router) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.fetchServices()
    this.fetchMissionSection()
    this.fetchMissionItems()
  }

  fetchServices(): void {
    this.serviceService.getAllServices().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.services = data
    })
  }

  fetchMissionSection() {
    this.overviewService.getSection2().subscribe(res => {
      this.mission = true
      this.missionSection = res
    })
  }
  fetchMissionItems() {
    this.overviewService.getMissionItems(this.missionId).subscribe(res => {
      this.missionItems = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.missionItems.push(item as HomeItems)
      })
    })
  }


}
