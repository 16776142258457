import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact, Service } from '../interfaces/service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private servicePath = '/services'
  serviceRef: AngularFirestoreCollection<Service>

  aboutCollection = 'services'
  section1: any
  section1Id: string = 's1'

  collection = this.db.collection('services')
  items = 'benefits'

  private contactPath = '/contactUs'
  contactRef: AngularFirestoreCollection<Contact>

  constructor(private db: AngularFirestore) {
    this.serviceRef = db.collection(this.servicePath)
    this.contactRef = db.collection(this.contactPath)
  }

  getAllServices(): AngularFirestoreCollection<Service> {
    return this.serviceRef
  }

  getService(id: string): Observable<Service> {
    return this.db.doc(this.aboutCollection + '/' + this.section1Id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Service;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  // Get all sub-collections
  getBenefits() {
    return this.collection.doc('s5').collection(this.items).snapshotChanges();
  }

  getAllContacts(): AngularFirestoreCollection<Contact> {
    return this.contactRef
  }

}
