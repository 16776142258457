import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Enquire } from '../interfaces/enquire';
import { Home } from '../interfaces/home';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  collectionName = 'home'
  homeSlide: any
  homeSlideId: string = 'h1'

  homeSlide2: any
  homeSlideId2: string = 'h2'

  homeSlide3: any
  homeSlideId3: string = 'h3'

  homeSlide4: any
  homeSlideId4: string = 'h4'

  homeSlide5: any
  homeSlideId5: string = 'h5'

  homeSlide6: any
  homeSlideId6: string = 'h6'

  homeSlide7: any
  homeSlideId7: string = 'h7'

  homeSlide8: any
  homeSlideId8: string = 'h8'

  homeSlide9: any
  homeSlideId9: string = 'h9'

  wuCollectionName = 'whyUs'

  wu1: any
  wu1id: string = 'w1'

  wu2: any
  wu2id: string = 'w2'

  wu3: any
  wu3id: string = 'w3'

  wu4: any
  wu4id: string = 'w4'

  private dbPath = '/enquire';

  enquireRef: AngularFirestoreCollection<Enquire>;

  collection = this.db.collection('home')
  section3Items = 'items'
  section9Lists = 'lists'
  section9ListItems = 'listItems'

  constructor(private db: AngularFirestore) {
    this.enquireRef = db.collection(this.dbPath);
  }

  getSlideSection(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection2(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId2).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  create(enquire: Enquire): any {
    return this.enquireRef.add({ ...enquire });
  }

  getSlideSection3(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId3).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  // Get all sub-collections
  getSection3Items(uid: string) {
    return this.collection.doc(uid).collection(this.section3Items).snapshotChanges();
  }

  getSlideSection4(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId4).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection5(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId5).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection6(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId6).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection7(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId7).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection8(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId8).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSlideSection9(): Observable<Home> {
    return this.db.doc(this.collectionName + '/' + this.homeSlideId9).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSection9Lists(uid: string) {
    return this.collection.doc(uid).collection(this.section9Lists).snapshotChanges();
  }

  getSection9ListItems(uid: string) {
    return this.collection.doc(uid).collection(this.section9ListItems).snapshotChanges();
  }

  getWhyUs1(): Observable<Home> {
    return this.db.doc(this.wuCollectionName + '/' + this.wu1id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getWhyUs2(): Observable<Home> {
    return this.db.doc(this.wuCollectionName + '/' + this.wu2id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getWhyUs3(): Observable<Home> {
    return this.db.doc(this.wuCollectionName + '/' + this.wu3id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getWhyUs4(): Observable<Home> {
    return this.db.doc(this.wuCollectionName + '/' + this.wu4id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Home;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

}
