<div class="logisco-page-title-wrap  logisco-style-custom logisco-center-align"
    style="background-image: url(../../../assets/theme/upload/about-1-title-bg.jpg) ;">
    <div class=logisco-header-transparent-substitute></div>
    <div class=logisco-page-title-overlay></div>
    <div class="logisco-page-title-container logisco-container">
        <div class="logisco-page-title-content logisco-item-pdlr">
            <h1 class="logisco-page-title" style="font-size: 58px ;text-transform: none ;letter-spacing: 0px ;">{{ title
                }}</h1>
            <div class=logisco-page-caption style="letter-spacing: 0px ;">
                {{ subtitle }}
            </div>
        </div>
    </div>
</div>

<div class=logisco-page-wrapper id=logisco-page-wrapper>
    <div class=gdlr-core-page-builder-body>

        <div class="gdlr-core-pbf-wrapper " style="padding: 30px 0px 75px 0px;">
            <div class=gdlr-core-pbf-background-wrap>
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/about-team-map-bg.jpg) ;background-size: cover ;background-position: bottom center ;"
                    data-parallax-speed=0.05></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div
                        class="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first gdlr-core-hide-in-mobile">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 60px 0px 0px 0px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align">
                                        <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                            style="border-width: 0px;margin: 0px 0px 0px 0px;">
                                            <span *ngIf="about">
                                                <a class="gdlr-core-lightgallery gdlr-core-js "
                                                    [href]="aboutSection.icon">
                                                    <img [src]="aboutSection.icon" alt width=340 height=340
                                                        title=about-1-top-image></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-40">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 80px 0px 0px 0px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                        style="padding-bottom: 27px ;" id=gdlr-core-title-item-1>
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 36px ;font-weight: 600 ;letter-spacing: 0px ;text-transform: none ;color: #143369 ;">
                                                <span *ngIf="about">
                                                    {{ aboutSection.title }}
                                                </span>
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                        style="padding-bottom: 23px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;font-weight: 400 ;text-transform: none ;color: #656565 ;">
                                            <span *ngIf="about">
                                                <span [innerHtml]="aboutSection.content"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 110px 0px 20px 0px;" id=gdlr-core-wrapper-1>
            <div class=gdlr-core-pbf-background-wrap>
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/about-1-globe-bg-1.jpg) ;background-size: cover ;background-position: top center ;"
                    data-parallax-speed=0.15></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container" style="min-height: 550px;">
                    <div class=gdlr-core-pbf-element>
                        <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                            style="padding-bottom: 55px ;" id=gdlr-core-title-item-2>
                            <div class="gdlr-core-title-item-title-wrap ">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                    style="font-size: 32px ;letter-spacing: 0px ;text-transform: none ;color: #143369 ;">
                                    Mission
                                    <span class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 0px 20px 0px 0px;" data-sync-height="height logis1">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-icon-list-item gdlr-core-item-pdlr gdlr-core-item-pdb clearfix ">
                                        <ul>

                                            <li class=" gdlr-core-skin-divider" style="margin-bottom: 50px ;"
                                                *ngFor="let item of missionItems">
                                                <span class=gdlr-core-icon-list-image style="margin-right: 38px ;">
                                                    <img [src]="item.icon" alt width=50 height=59 title="clock-icon">
                                                </span>
                                                <div class="gdlr-core-icon-list-content-wrap">
                                                    <span class="gdlr-core-icon-list-content"
                                                        style="color: #252525 ;font-size: 20px ;font-weight: 700 ;">{{
                                                        item.title }}</span>
                                                    <span class=gdlr-core-icon-list-caption
                                                        style="color: #4f5d77 ;font-size: 16px ;font-weight: 400 ;">
                                                        {{ item.content }}
                                                    </span>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height="height logis1">
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 22px ;font-weight: 400 ;text-transform: none ;color: #090909 ;">
                                            <span *ngIf="mission">
                                                <span [innerHtml]="missionSection.title"></span>
                                            </span>

                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;font-weight: 400 ;text-transform: none ;color: #656565 ;">
                                            <span *ngIf="mission">
                                                <span [innerHtml]="missionSection.content"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 50px 0px 100px 0px;" id=gdlr-core-wrapper-4>
            <div class="gdlr-core-pbf-background-wrap"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height=bg1>
                            <div class="gdlr-core-pbf-background-wrap" style="min-height: 600px;">
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    style="background-image: url(../../../assets/theme/upload/man-smile-1.jpg) ;background-size: cover ;background-position: center ; "
                                    data-parallax-speed="0"></div>
                            </div>
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                            </div>
                        </div>
                    </div>
                    <div class="gdlr-core-pbf-column gdlr-core-column-30" style="min-height: 600px;">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 60px 0px 30px 50px;" data-sync-height=bg1>
                            <div
                                class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-image-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-left-align"
                                        style="padding-bottom: 25px ;">
                                        <div class="gdlr-core-image-item-wrap gdlr-core-media-image  gdlr-core-image-item-style-rectangle"
                                            style="border-width: 0px;"><img
                                                src="../../../assets/theme/upload/paper-icon2.png" alt width=59
                                                height=68 title=paper-icon2></div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                                        <span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 14px ;font-style: normal ;text-transform: uppercase ;color: #919191 ;margin-bottom: 6px ;">
                                            <span *ngIf="whyUs">
                                                <span [innerHtml]="whyUsSection.title"></span>
                                            </span>
                                        </span>
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 32px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #000000 ;">
                                                <span *ngIf="whyUs">
                                                    <span [innerHtml]="whyUsSection.subtitle"></span>
                                                </span>
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 17px ;font-weight: 400 ;text-transform: none ;color: #727272 ;">
                                            <span *ngIf="whyUs">
                                                <span [innerHtml]="whyUsSection.content"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align gdlr-core-no-p-space"
                                        style="padding-bottom: 25px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 21px ;font-weight: 400 ;text-transform: none ;color: #2b2b2b ;">
                                            <span *ngIf="whyUs">
                                                <span [innerHtml]="whyUsSection.content2"></span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <a class="gdlr-core-button  gdlr-core-button-transparent gdlr-core-button-with-border"
                                            routerLink='/services'
                                            style="font-size: 18px ;font-weight: 400 ;letter-spacing: 0px ;color: #e53d34 ;padding: 0px 0px 2px 0px;text-transform: none ;border-radius: 0px;-moz-border-radius: 0px;-webkit-border-radius: 0px;border-width: 0px 0px 1px 0px;border-color: #e53d34 ;">
                                            <span class=gdlr-core-content>Our Services</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>