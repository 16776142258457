<header class="logisco-header-wrap logisco-header-style-plain  logisco-style-menu-right logisco-sticky-navigation logisco-style-slide"
        data-navigation-offset="75">
        <div class="logisco-header-background"></div>
        <div class="logisco-header-container  logisco-header-full">
                <div class="logisco-header-container-inner clearfix">

                        <div class="logisco-logo  logisco-item-pdlr">
                                <div class=logisco-logo-inner>

                                        <a *ngFor="let item of footer" class href="/">
                                                <img [src]="item.logo" alt="XL Express" width="259" height="84"
                                                        title="logo">
                                        </a>
                                </div>
                        </div>

                        <div class="logisco-navigation logisco-item-pdlr clearfix ">
                                <div class=logisco-main-menu id=logisco-main-menu>
                                        <ul id=menu-main-navigation-1 class=sf-menu>

                                                <li *ngFor="let menu of menus" class="menu-item logisco-normal-menu"
                                                        [ngClass]="{'menu-item-has-children': menu.hasKids}"
                                                        routerLinkActive="current-menu-item">
                                                        <a [routerLink]="menu.url" class=sf-with-ul-pre>
                                                                {{ menu.title }}
                                                        </a>
                                                        <ul *ngIf="menu.hasKids == true" class=sub-menu>
                                                                <li *ngFor="let child of menu.children"
                                                                        class="menu-item"
                                                                        routerLinkActive="current-menu-item"
                                                                        data-size=100>
                                                                        <a [routerLink]="child.url">
                                                                                {{ child.title }}
                                                                        </a>
                                                                </li>
                                                        </ul>
                                                </li>
                                        </ul>
                                        <div class=logisco-navigation-slide-bar id=logisco-navigation-slide-bar></div>
                                </div>
                        </div>
                </div>
        </div>
</header>