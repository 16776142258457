import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Footer, FooterCompanies, FooterUsefulLinks } from 'src/app/interfaces/footer';
import { SocialMedia, TopBar } from 'src/app/interfaces/topbar';
import { FooterService } from 'src/app/services/footer.service';
import { TopbarService } from 'src/app/services/topbar.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  topBars?: TopBar[]
  socialMedias?: SocialMedia[]
  footer?: Footer[]
  usefulLinks?: any
  companies?: any
  footerId = 'yznT7zihpaYRd75XBcxw'
  year = new Date().getFullYear()

  constructor(private topBarService: TopbarService, private footerService: FooterService, private router: Router) { }

  ngOnInit(): void {
    this.fetchFooter()
    this.fetchUsefulLinks()
    this.fetchFooterCompanies()
    this.fetchTopBars()
    this.fetchSocialMedias()
  }

  fetchFooter(): void {
    this.footerService.getFooter().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.footer = data
    })
  }

  fetchTopBars(): void {
    this.topBarService.getTopBars().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.topBars = data
    })
  }

  fetchSocialMedias(): void {
    this.topBarService.getSocialMedias().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.socialMedias = data
    })
  }

  // Fetch All useful links
  fetchUsefulLinks() {
    this.footerService.getUsefulLinks(this.footerId).subscribe(res => {
      this.usefulLinks = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.usefulLinks.push(item as FooterUsefulLinks)
      })
    })
  }

  // Fetch All companies
  fetchFooterCompanies() {
    this.footerService.getCompanies(this.footerId).subscribe(res => {
      this.companies = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.companies.push(item as FooterCompanies)
      })
    })
  }

}
