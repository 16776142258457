import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HomeComponent } from 'src/app/components/home/home.component';
import { ContactUsComponent } from 'src/app/components/contact-us/contact-us.component';
import { OverviewComponent } from 'src/app/components/overview/overview.component';
import { ServicesComponent } from 'src/app/components/services/services.component';
import { ServiceComponent } from 'src/app/components/service/service.component';
import { TrackerComponent } from 'src/app/components/tracker/tracker.component';
import { DefaultComponent } from './default.component';



@NgModule({
  declarations: [
    DefaultComponent,
    HomeComponent,
    ContactUsComponent,
    OverviewComponent,
    ServicesComponent,
    ServiceComponent,
    TrackerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DefaultModule { }
