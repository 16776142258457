import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Service } from 'src/app/interfaces/service';
import { ServiceService } from 'src/app/services/service.service';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})
export class ServiceComponent implements OnInit {

  title: string = 'Service'
  subtitle: any

  serviceUrl: any
  serviceId: any

  service?: any
  serviceLoaded: boolean = false
  benefits: any

  services?: Service[]

  constructor(private route: ActivatedRoute, private serviceService: ServiceService) { }

  ngOnInit(): void {
    this.serviceUrl = this.route.snapshot.paramMap.get("id")
    var chars = this.serviceUrl.split('-');
    this.serviceId = chars[0]
    this.fetchService()
    this.fetchBenefits()
    this.fetchServices()
  }

  fetchService() {
    this.serviceService.getService(this.serviceId).subscribe(res => {
      this.serviceLoaded = true
      this.service = res
      this.subtitle = this.service.title
    })
  }

  fetchBenefits() {
    this.serviceService.getBenefits().subscribe(res => {
      this.benefits = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.benefits.push(item)
      })
    })
  }

  fetchServices(): void {
    this.serviceService.getAllServices().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.services = data
    })
  }

}
