export const environment = {
  production: true,
  firebaseConfig: {
    // apiKey: "AIzaSyBGkBYwzCPAP9200vD3D7qgTJ3bqg9Bv9s",
    // authDomain: "exl-website.firebaseapp.com",
    // projectId: "exl-website",
    // storageBucket: "exl-website.appspot.com",
    // messagingSenderId: "28466505008",
    // appId: "1:28466505008:web:0594be3baa025acf4454f5"

    apiKey: "AIzaSyDw6b1uOTEtN8ui0zo4fMOPyhmfJI6On9I",
    authDomain: "xlexpress-67464.firebaseapp.com",
    databaseURL: "https://xlexpress-67464.firebaseio.com",
    projectId: "xlexpress-67464",
    storageBucket: "xlexpress-67464.appspot.com",
    messagingSenderId: "545863118292",
    appId: "1:545863118292:web:6fc49e64c684040c9b7a41",
    measurementId: "G-SEMWXREFR7"
  }
};
