<div class=logisco-page-wrapper id=logisco-page-wrapper>
    <div class=gdlr-core-page-builder-body>
        <div class="gdlr-core-pbf-wrapper " style="padding: 350px 0px 160px 0px;" id="gdlr-core-wrapper-1">
            <div class=gdlr-core-pbf-background-wrap>
                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style="background-image: url(../../../assets/theme/upload/bg-3.jpg) ;background-size: cover ;background-position: center ;"
                    data-parallax-speed=0.3></div>
            </div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js " data-gdlr-animation=fadeInUp
                data-gdlr-animation-duration=600ms data-gdlr-animation-offset=0.8>
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class=gdlr-core-pbf-element>
                        <div
                            class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr">
                            <div class="gdlr-core-title-item-title-wrap ">
                                <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                    style="font-size: 75px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;color: #ffffff ;">
                                    Contact Us<span
                                        class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                </h3>
                            </div><span class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                style="font-size: 25px ;font-style: normal ;color: #f2f2f2 ;margin-top: 25px ;">Get
                                Intouch</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="gdlr-core-pbf-wrapper " style="padding: 45px 0px 35px 0px;">
            <div class=gdlr-core-pbf-background-wrap style="background-color: #ffffff ;"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">

                    <div class="gdlr-core-pbf-column gdlr-core-column-20" *ngFor="let contact of contacts">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 50px 20px 0px 20px;">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-icon-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <i [ngClass]="contact.icon"
                                            style="color: #aa1e25 ;font-size: 40px ;min-width: 40px ;min-height: 40px ;"></i>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                        style="padding-bottom: 25px ;">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 23px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                                                {{ contact.title }}<span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 0px ;">
                                        <div class=gdlr-core-text-box-item-content style="font-size: 16px ;">
                                            <p>{{ contact.content }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div
                                        class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 16px ;text-transform: none ;">
                                            <p><a [href]="contact.url">{{ contact.subtitle }}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="gdlr-core-pbf-wrapper " style="padding: 85px 0px 20px 0px;">
            <div class=gdlr-core-pbf-background-wrap style="background-color: #f3f3f3 ;"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js " style="max-width: 760px ;">
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-center-align gdlr-core-title-item-caption-bottom gdlr-core-item-pdlr"
                                        style="padding-bottom: 45px ;">
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 39px ;letter-spacing: 0px ;text-transform: none ;">
                                                Leave us your info <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 19px ;font-style: normal ;">and we will get
                                            back to you.</span>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-contact-form-7-item gdlr-core-item-pdlr gdlr-core-item-pdb ">
                                        <div role=form class=wpcf7 id=wpcf7-f1979-p1964-o1 lang=en-US dir=ltr>

                                            <form class="" (ngSubmit)="onSubmit()" [formGroup]="form">

                                                <div class="quform-elements">
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-name">
                                                            <input id="name" type="text" name="name" size="40"
                                                                class="input1" required placeholder="Name*" #name
                                                                formControlName="name">
                                                        </span>

                                                    </div>
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-email">
                                                            <input id="email" type="email" name="email" size="40"
                                                                class="input1" required placeholder="Email Address*"
                                                                #email formControlName="email">
                                                        </span>

                                                    </div>
                                                    <div class="quform-element">

                                                        <span class="wpcf7-form-control-wrap your-message">
                                                            <textarea id="message" name="message" cols="40" rows="10"
                                                                class="input1" required placeholder="Message*" #message
                                                                formControlName="message"></textarea>
                                                        </span>

                                                    </div>
                                                    <!-- Begin Submit button -->
                                                    <div class="quform-submit quform-submit-inner2">
                                                        <div class="quform-submit-inner">
                                                            <button class="submit-button" (click)="onSubmit()">
                                                                <span *ngIf="isLoading">
                                                                    <i class="fa fa-spinner fa-spin"></i> Processing
                                                                </span>
                                                                <span *ngIf="!isLoading">Send</span>
                                                            </button>
                                                        </div>
                                                        <div class="quform-loading-wrap"><span
                                                                class="quform-loading"></span></div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 0px 0px 0px 0px;">
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-pbf-wrapper-full-no-space">
                    <div class=gdlr-core-pbf-element>
                        <div class="gdlr-core-wp-google-map-plugin-item gdlr-core-item-pdlr gdlr-core-item-pdb "
                            style="padding-bottom: 0px ;">
                            <div style="overflow:hidden;width: 100%;position: relative;">

                                <iframe style="width:100%; height:480px; border:0"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.3272562639913!2d3.350527650217732!3d6.606194595199192!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9233bdfa35bb%3A0x3816a9815bd9d381!2s12b%20Ogunmodede%20St%2C%20Allen%20101233%2C%20Ikeja!5e0!3m2!1sen!2sng!4v1630446261251!5m2!1sen!2sng"
                                    width="1300" height="600" style="border:0;" allowfullscreen=""
                                    loading="lazy"></iframe>
                                <div
                                    style="position: absolute;width: 80%;bottom: 20px;left: 0;right: 0;margin-left: auto;margin-right: auto;color: #000;">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gdlr-core-pbf-wrapper " style="padding: 80px 0px 50px 0px;">
            <div class=gdlr-core-pbf-background-wrap style="background-color: #ffffff ;"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js ">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
                    <div class=gdlr-core-pbf-element>
                        <div
                            class="gdlr-core-social-network-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-item-pdlr">

                            <a *ngFor="let socialMedia of socialMedias" [href]="socialMedia.url" target="_blank"
                                class="gdlr-core-social-network-icon" style="font-size: 30px ;color: #aa1e25 ;">
                                <i [ngClass]="socialMedia.icon"></i>
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>