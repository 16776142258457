<div class="logisco-mobile-header-wrap">
    <div class="logisco-mobile-header logisco-header-background logisco-style-slide logisco-sticky-mobile-navigation "
        id="logisco-mobile-header">
        <div class="logisco-mobile-header-container logisco-container clearfix">

            <div class="logisco-logo  logisco-item-pdlr">
                <div class="logisco-logo-inner">
                    <a *ngFor="let item of footer" class href="/">
                        <img [src]="item.logo" alt="XL Express" width="259" height="84" title="logo">
                    </a>
                </div>
            </div>

            <div class="logisco-mobile-menu-right">

                <div class="logisco-mobile-menu">
                    <a class="logisco-mm-menu-button logisco-mobile-menu-button logisco-mobile-button-hamburger"
                        href=#logisco-mobile-menu><span></span>
                    </a>
                    <div class="logisco-mm-menu-wrap logisco-navigation-font" id=logisco-mobile-menu data-slide="right">
                        <ul id=menu-main-navigation class=m-menu>

                            <li *ngFor="let menu of menus" class="menu-item"
                                [ngClass]="{'menu-item-has-children': menu.hasKids}"
                                routerLinkActive="current-menu-item">
                                <a [routerLink]="menu.url" class=sf-with-ul-pre>
                                    {{ menu.title }}
                                </a>
                                <ul *ngIf="menu.hasKids == true" class=sub-menu>
                                    <li *ngFor="let child of menu.children" class="menu-item"
                                        routerLinkActive="current-menu-item" data-size=100>
                                        <a [routerLink]="child.url">
                                            {{ child.title }}
                                        </a>
                                    </li>
                                </ul>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>