import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { TopBar } from '../interfaces/topbar';

@Injectable({
  providedIn: 'root'
})
export class TopbarService {

  private topBarPath = '/topbar'
  topBarRef: AngularFirestoreCollection<TopBar>

  private socialMediasPath = '/socialMedias'
  socialMediasRef: AngularFirestoreCollection<TopBar>

  constructor(private db: AngularFirestore) {
    this.topBarRef = db.collection(this.topBarPath)
    this.socialMediasRef = db.collection(this.socialMediasPath)
  }

  getTopBars(): AngularFirestoreCollection<TopBar> {
    return this.topBarRef
  }

  getSocialMedias(): AngularFirestoreCollection<TopBar> {
    return this.socialMediasRef
  }

}
