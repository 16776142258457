<div class="app-sidebar">
    <div class="app-sidebar-top">
        <a [href]="androidStore" target="_blank">
            <div class="icons">
                <i class="fa fa-mobile"></i> <br>
                <p class="sm">Mobile</p>
                <p class="smd">App</p>

                <span class="icons-display">
                    <p class="smp">{{ appInfo }}</p>
                    <img [src]="android" alt="">
                </span>
            </div>
        </a>
        <a routerLink="/tracker">
            <div class="icons">
                <i class="fa fa-map-marker"></i><br>
                <p class="sm">Track</p>
                <p class="smd">Package</p>
                <span class="icons-display">
                    <p class="smp">{{ trackerInfo }}</p>
                </span>
            </div>
        </a>
    </div>
    <div class="app-sidebar-bottom">
        <div class="icons" (click)="scrollToTop()">
            <i class="fa fa-arrow-up"></i><br>
            <p class="sm">Go To</p>
            <p class="smd">Top</p>
        </div>
    </div>
</div>