<div class="logisco-page-title-wrap  logisco-style-custom logisco-center-align">
    <div class=logisco-header-transparent-substitute></div>
    <div class=logisco-page-title-overlay></div>
    <div class="logisco-page-title-container logisco-container">
        <div class="logisco-page-title-content logisco-item-pdlr">
            <h1 class="logisco-page-title">{{ title }}</h1>
            <div class=logisco-page-caption> {{ subtitle }} </div>

            <div class="form-holder">
                <form class="form-inline" (ngSubmit)="onTrack()" [formGroup]="trackForm">
                    <input type="text" id="userId" required placeholder="User ID*" #userId formControlName="userId">
                    <input type="text" id="abn" required placeholder="Airway Bill Number*" #abn formControlName="abn">
                    <button (click)="onTrack()">
                        <span *ngIf="isLoading">
                            <i class="fa fa-spinner fa-spin"></i>&nbsp; Tracking
                        </span>
                        <span *ngIf="!isLoading">
                            <i class="fa fa-map-marker"></i> &nbsp; Start Tracking
                        </span>
                    </button>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="logisco-page-wrapper" id="logisco-page-wrapper">
    <div class=gdlr-core-page-builder-body id="searchzone">

        <div class="gdlr-core-pbf-wrapper " style="padding: 40px 0px 50px 0px;">
            <div class="gdlr-core-pbf-background-wrap"></div>
            <div class="gdlr-core-pbf-wrapper-content gdlr-core-js">
                <div class="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container-custom"
                    style="max-width: 1280px; min-height: 1500px;">
                    <div class="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js " data-sync-height=half-height
                            data-sync-height-center>
                            <div class="gdlr-core-pbf-background-wrap">
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    data-parallax-speed=0>
                                </div>
                            </div>

                            <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                                style="padding: 35px 40px 55px 60px; " data-sync-height="half-height-2"
                                data-sync-height-center>
                                <div class=gdlr-core-pbf-background-wrap
                                    style="box-shadow: 0 0 35px rgba(10, 10, 10,0.07); -moz-box-shadow: 0 0 35px rgba(10, 10, 10,0.07); -webkit-box-shadow: 0 0 35px rgba(10, 10, 10,0.07); ">
                                    <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                        style="background-image: url(../../../assets/theme/upload/hp-half-bg-3.jpg) ;background-size: cover ;background-position: center ;"
                                        data-parallax-speed=0></div>
                                </div>
                                <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"
                                    *ngIf="fetched">

                                    <section class="root">
                                        <figure>
                                            <span>
                                                <img [src]="dispatchRequest.item.imageUrl">
                                            </span>
                                            <figcaption>
                                                <h4>Tracking Details</h4>
                                                <h6>Airway Bill Number</h6>
                                                <h2>{{ airwayBillNumber }}</h2>
                                            </figcaption>
                                        </figure>
                                        <div class="order-track">

                                            <div class="order-track-step" *ngFor="let tracker of trackers">
                                                <div class="order-track-status">
                                                    <span class="order-track-status-dot"></span>
                                                    <span class="order-track-status-line"></span>
                                                </div>
                                                <div class="order-track-text">
                                                    <p class="order-track-text-stat">
                                                        {{ tracker.title}}
                                                    </p>
                                                    <span class="order-track-text-sub">
                                                        {{ tracker.currentLocation }}
                                                    </span>
                                                    <br>
                                                    <span class="order-track-text-sub">
                                                        {{ tracker.addedOn | date:'yyyy/MM/dd h:mm:ss a' }}
                                                    </span>
                                                </div>
                                            </div>

                                        </div>
                                    </section>


                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="gdlr-core-pbf-column gdlr-core-column-30" id=gdlr-core-column-8>
                        <div class="gdlr-core-pbf-column-content-margin gdlr-core-js "
                            style="padding: 40px 60px 60px 60px;" data-sync-height=half-height>
                            <div class=gdlr-core-pbf-background-wrap>
                                <div class="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                                    data-parallax-speed=0>
                                </div>
                            </div>
                            <div class="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content"
                                data-gdlr-animation=fadeInRight data-gdlr-animation-duration=600ms
                                data-gdlr-animation-offset=0.8>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                                        style="padding-bottom: 25px ;"><span
                                            class="gdlr-core-title-item-caption gdlr-core-info-font gdlr-core-skin-caption"
                                            style="font-size: 14px ;font-style: normal ;letter-spacing: 1px ;text-transform: uppercase ;margin-bottom: 0px ;">
                                            <span *ngIf="fetched">
                                                Category: {{ dispatchRequest.item.itemType }}
                                            </span>
                                        </span>
                                        <div class="gdlr-core-title-item-title-wrap ">
                                            <h3 class="gdlr-core-title-item-title gdlr-core-skin-title "
                                                style="font-size: 40px ;font-weight: 700 ;letter-spacing: 0px ;text-transform: none ;">
                                                <span *ngIf="fetched">
                                                    {{ dispatchRequest.item.title }}
                                                </span>
                                                <span
                                                    class="gdlr-core-title-item-title-divider gdlr-core-skin-divider"></span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div class=gdlr-core-pbf-element>
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 6px ;">
                                        <div class=gdlr-core-text-box-item-content
                                            style="font-size: 21px ;text-transform: none ;">
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Delivery Mode: </small>
                                                    {{ dispatchRequest.item.transportation }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Item Valued At: </small>
                                                    ₦{{ dispatchRequest.item.valuedAt | number }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Is Item Fragile?: </small>
                                                    <span *ngIf="dispatchRequest.item.isFragile">
                                                        This package is fragile, handle with care.
                                                    </span>
                                                    <span *ngIf="!dispatchRequest.item.isFragile">This item is not
                                                        fragile.</span>
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Item Weight: </small>
                                                    {{ dispatchRequest.item.weight }} kG
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="gdlr-core-pbf-element">
                                    <div class="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align"
                                        style="padding-bottom: 15px;">
                                        <div class="gdlr-core-text-box-item-content"
                                            style="font-size: 17px; text-transform: none ;">
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Estimated Distance: </small>
                                                    {{ dispatchRequest.location.distance }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSlim">Estimated Duration: </small>
                                                    {{ dispatchRequest.location.duration }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSmall">Item picked up at: <br></small>
                                                    {{ dispatchRequest.location.pickupAddress }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSmall">Recipient address: <br></small>
                                                    {{ dispatchRequest.location.recipientAddress }}
                                                </span>
                                            </p>
                                            <p>
                                                <span *ngIf="fetched">
                                                    <small class="isSmall">Item Description: <br></small>
                                                    {{ dispatchRequest.item.description }}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="gdlr-core-pbf-element" *ngIf="fetched">
                                    <div
                                        class="gdlr-core-button-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                                        <a class="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
                                            routerLink='/' id="gdlr-core-button-id-23256">
                                            <span class="gdlr-core-content">
                                                Close & Return to Home
                                            </span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</div>