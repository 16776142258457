import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { HomeItems } from 'src/app/interfaces/home';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title: string = 'Home'
  homeSlide1: boolean = false
  homeSlide2: boolean = false
  homeSlide3: boolean = false
  homeSlide4: boolean = false
  homeSlide5: boolean = false
  homeSlide6: boolean = false
  homeSlide7: boolean = false
  homeSlide8: boolean = false
  homeSlide9: boolean = false
  slideSection: any
  slideSection2: any
  slideSection3: any
  isLoading: boolean = false
  section3Items?: any
  section3Id = 'h3'
  slideSection4?: any
  slideSection5?: any
  slideSection6?: any
  slideSection7?: any
  slideSection8?: any
  slideSection9?: any
  section9Id = 'h9'
  section9Lists?: any
  section9ListItems?: any

  wu1: boolean = false
  wuData1?: any

  wu2: boolean = false
  wuData2?: any

  wu3: boolean = false
  wuData3?: any

  wu4: boolean = false
  wuData4?: any

  constructor(private titleService: Title, private homeService: HomeService, private formBuilder: FormBuilder, private toastr: ToastrService, private router: Router) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.fetchSlideSection()
    this.fetchSlideSection2()
    this.fetchSlideSection3()
    this.fetchSection3Items()
    this.fetchSlideSection4()
    this.fetchSlideSection5()
    this.fetchSlideSection6()
    this.fetchSlideSection7()
    this.fetchSlideSection8()
    this.fetchWhyUs1()
    this.fetchWhyUs2()
    this.fetchWhyUs3()
    this.fetchWhyUs4()
  }

  fetchSlideSection() {
    this.homeService.getSlideSection().subscribe(res => {
      this.homeSlide1 = true
      this.slideSection = res
    })
  }

  fetchSlideSection2() {
    this.homeService.getSlideSection2().subscribe(res => {
      this.homeSlide2 = true
      this.slideSection2 = res
    })
  }

  fetchSlideSection3() {
    this.homeService.getSlideSection3().subscribe(res => {
      this.homeSlide3 = true
      this.slideSection3 = res
    })
  }

  // Fetch All section 3 items
  fetchSection3Items() {
    this.homeService.getSection3Items(this.section3Id).subscribe(res => {
      this.section3Items = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.section3Items.push(item as HomeItems)
      })
    })
  }

  fetchSlideSection4() {
    this.homeService.getSlideSection4().subscribe(res => {
      this.homeSlide4 = true
      this.slideSection4 = res
    })
  }

  fetchSlideSection5() {
    this.homeService.getSlideSection5().subscribe(res => {
      this.homeSlide5 = true
      this.slideSection5 = res
    })
  }

  fetchSlideSection6() {
    this.homeService.getSlideSection6().subscribe(res => {
      this.homeSlide6 = true
      this.slideSection6 = res
    })
  }

  fetchSlideSection7() {
    this.homeService.getSlideSection7().subscribe(res => {
      this.homeSlide7 = true
      this.slideSection7 = res
    })
  }

  fetchSlideSection8() {
    this.homeService.getSlideSection8().subscribe(res => {
      this.homeSlide8 = true
      this.slideSection8 = res
    })
  }

  fetchWhyUs1() {
    this.homeService.getWhyUs1().subscribe(res => {
      this.wu1 = true
      this.wuData1 = res
    })
  }

  fetchWhyUs2() {
    this.homeService.getWhyUs2().subscribe(res => {
      this.wu2 = true
      this.wuData2 = res
    })
  }

  fetchWhyUs3() {
    this.homeService.getWhyUs3().subscribe(res => {
      this.wu3 = true
      this.wuData3 = res
    })
  }

  fetchWhyUs4() {
    this.homeService.getWhyUs4().subscribe(res => {
      this.wu4 = true
      this.wuData4 = res
    })
  }

  // fetchSlideSection9() {
  //   this.homeService.getSlideSection9().subscribe(res => {
  //     this.homeSlide9 = true
  //     this.slideSection9 = res
  //   })
  // }

  // // Fetch All section 9 lists
  // fetchSection9Items() {
  //   this.homeService.getSection9Lists(this.section9Id).subscribe(res => {
  //     this.section9Lists = []
  //     res.forEach(a => {
  //       let item: any = a.payload.doc.data()
  //       item.id = a.payload.doc.id
  //       this.section9Lists.push(item as HomeItems)
  //     })
  //   })
  // }


  form = this.formBuilder.group({
    name: ['', { validators: [Validators.required], updateOn: "change" }],
    message: ['', { validators: [Validators.required], updateOn: "change" }],
    email: ['', { validators: [Validators.required, Validators.email, Validators.maxLength(80)], updateOn: "change" }]
  })

  /**
   * Send Message
   * @param name
   * @param message
   * @param email
   * @returns user data
   */
  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.error('Provide valid data. Remember all fields are required.', 'Request Failed')
      return
    }

    this.isLoading = true

    let name = this.form.value.name
    let email = this.form.value.email
    let message = this.form.value.message

    let data = {
      name: name,
      email: email,
      message: message,
      responded: false,
    }

    this.homeService.create(data).then(() => {
      this.form.reset();
      this.isLoading = false
      this.toastr.success('Your message has been received, we contact you shortly. Best Regards.', 'Request Successful')
    });
  }

}
