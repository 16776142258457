import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { HomeComponent } from './components/home/home.component';
import { OverviewComponent } from './components/overview/overview.component';
import { ServiceComponent } from './components/service/service.component';
import { ServicesComponent } from './components/services/services.component';
import { TrackerComponent } from './components/tracker/tracker.component';
import { DefaultComponent } from './layouts/default/default.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'home',
        component: HomeComponent,
        data: { title: 'Home' }
      },
      {
        path: 'overview',
        component: OverviewComponent,
        data: { title: 'Overview' }
      },
      {
        path: 'services',
        component: ServicesComponent,
        data: { title: 'Services' }
      },
      {
        path: 'service/:id',
        component: ServiceComponent,
        data: { title: 'Service' }
      },
      {
        path: 'tracker',
        component: TrackerComponent,
        data: { title: 'Tracker' }
      },
      {
        path: 'contact-us',
        component: ContactUsComponent,
        data: { title: 'Contact Us' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
