<app-mobile-header></app-mobile-header>

<div class="logisco-body-outer-wrapper ">
    <div class="logisco-body-wrapper clearfix  logisco-with-transparent-header logisco-with-frame">
        <div class="logisco-header-background-transparent">

            <app-topbar></app-topbar>

            <app-header></app-header>

        </div>

        <router-outlet></router-outlet>

        <app-sticky-menu></app-sticky-menu>

        <app-footer></app-footer>

    </div>
</div>