import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Overview } from '../interfaces/overview';

@Injectable({
  providedIn: 'root'
})
export class OverviewService {

  aboutCollection = 'aboutUs'
  missionCollection = 'mission'
  section1: any
  section1Id: string = 'a1'
  section2: any
  section2Id: string = 'm1'
  section3: any
  section3Id: string = 'a2'

  collection = this.db.collection('mission')
  missionItems = 'items'

  constructor(private db: AngularFirestore) { }

  getSection1(): Observable<Overview> {
    return this.db.doc(this.aboutCollection + '/' + this.section1Id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Overview;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  // Get all sub-collections
  getMissionItems(uid: string) {
    return this.collection.doc(uid).collection(this.missionItems).snapshotChanges();
  }

  getSection2(): Observable<Overview> {
    return this.db.doc(this.missionCollection + '/' + this.section2Id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Overview;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

  getSection3(): Observable<Overview> {
    return this.db.doc(this.aboutCollection + '/' + this.section3Id).snapshotChanges().pipe(
      map(a => {
        const data = a.payload.data() as Overview;
        const id = a.payload.id
        return { id, ...data }
      })
    )
  }

}
