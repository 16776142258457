import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Tracker } from 'src/app/interfaces/tracker';
import { TrackerService } from 'src/app/services/tracker.service';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.css']
})
export class TrackerComponent implements OnInit {

  title: string = 'Package Tracker'
  subtitle: string = 'Enter package Airway Bill Number below to track'

  isLoading: boolean = false
  fetched: boolean = false
  dispatchRequest: any
  trackers: any

  abn: any
  airwayBillNumber: any
  userId: any
  requestId: any

  constructor(private titleService: Title, private router: Router, private trackerService: TrackerService, private formBuilder: FormBuilder, private toastr: ToastrService, private _vps: ViewportScroller) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
  }

  scrollFn(anchor: string): void {
    this._vps.scrollToAnchor(anchor)
  }

  //  Track Package
  trackForm = this.formBuilder.group({
    abn: ['', { validators: [Validators.required], updateOn: "change" }],
    userId: ['', { validators: [Validators.required], updateOn: "change" }],
  })

  /**
   * Send Message
   * @param abn
   * @returns user data
   */
  async onTrack() {
    if (this.trackForm.invalid) {
      this.toastr.error('Provide valid data. Remember your user ID and package Airway Bill Number are required.', 'Request Failed')
      return
    }

    this.isLoading = true

    this.abn = this.trackForm.value.abn
    this.userId = this.trackForm.value.userId

    this.fetchDispatchRequestTrackings(this.abn)

  }

  // Fetch dispatch request
  fetchDispatchRequest(id: string, uid: string) {
    this.trackerService.getDispatchRequests(id, uid).subscribe(res => {
      this.dispatchRequest = res
      this.fetched = true
    })
  }

  // Fetch dispatched request tracking data
  fetchDispatchRequestTrackings(abn: number) {
    this.trackerService.getPackageTrackings(abn).subscribe(res => {
      this.trackers = []
      this.requestId = ''
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.trackers.push(item as Tracker)
        this.requestId = item.requestId
      })

      this.airwayBillNumber = Number(abn)

      // Fetch dispatch request
      var uid = this.userId
      this.fetchDispatchRequest(this.requestId, uid)

      this.isLoading = false

      // Scroll to search zone
      this.scrollFn('searchzone')
    })
  }

}
