import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { SocialMedia, TopBar } from 'src/app/interfaces/topbar';
import { HomeService } from 'src/app/services/home.service';
import { ServiceService } from 'src/app/services/service.service';
import { TopbarService } from 'src/app/services/topbar.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  title: string = 'Contact Us'
  subtitle: string = 'Get In Touch '

  contacts?: any

  isLoading: boolean = false

  topBars?: TopBar[]
  socialMedias?: SocialMedia[]

  constructor(private titleService: Title, private serviceService: ServiceService, private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService, private homeService: HomeService, private topBarService: TopbarService,) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.fetchServices()
    this.fetchSocialMedias()
  }

  fetchSocialMedias(): void {
    this.topBarService.getSocialMedias().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.socialMedias = data
    })
  }

  fetchServices(): void {
    this.serviceService.getAllContacts().snapshotChanges().pipe(
      map(changes => changes.map(c => ({
        id: c.payload.doc.id, ...c.payload.doc.data()
      })
      ))
    ).subscribe(data => {
      this.contacts = data
    })
  }

  form = this.formBuilder.group({
    name: ['', { validators: [Validators.required], updateOn: "change" }],
    message: ['', { validators: [Validators.required], updateOn: "change" }],
    email: ['', { validators: [Validators.required, Validators.email, Validators.maxLength(80)], updateOn: "change" }]
  })

  /**
   * Send Message
   * @param name
   * @param message
   * @param email
   * @returns user data
   */
  async onSubmit() {
    if (this.form.invalid) {
      this.toastr.error('Provide valid data. Remember all fields are required.', 'Request Failed')
      return
    }

    this.isLoading = true

    let name = this.form.value.name
    let email = this.form.value.email
    let message = this.form.value.message

    let data = {
      name: name,
      email: email,
      message: message,
      responded: false,
    }

    this.homeService.create(data).then(() => {
      this.form.reset();
      this.isLoading = false
      this.toastr.success('Your message has been received, we contact you shortly. Best Regards.', 'Request Successful')
    });
  }

}
