import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-menu',
  templateUrl: './sticky-menu.component.html',
  styleUrls: ['./sticky-menu.component.css']
})
export class StickyMenuComponent implements OnInit {

  appInfo = 'Express Delivery app provides users seamless shipment creation from the comfort of their homes and offices.'

  android = '../../../../assets/theme/images/androidSmall.png'
  androidStore = 'https://play.google.com/store/apps/details?id=com.xllogistics'

  trackerInfo = 'Get real-time update on your package delivery progress.'

  constructor(private scroll: ViewportScroller) { }

  ngOnInit(): void {
  }

  scrollToTop() {
    this.scroll.scrollToPosition([0, 0]);
  }

}
