import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HomeItems } from 'src/app/interfaces/home';
import { OverviewService } from 'src/app/services/overview.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  title: string = 'Overview'
  subtitle: string = 'Our Litle Story'

  about: boolean = false
  aboutSection: any

  mission: boolean = false
  missionSection: any
  missionId = 'm1'
  missionItems?: any

  whyUs: boolean = false
  whyUsSection: any

  constructor(private titleService: Title, private overviewService: OverviewService, private router: Router) {
    this.titleService.setTitle(this.title);
  }

  ngOnInit(): void {
    this.fetchAboutSection()
    this.fetchMissionSection()
    this.fetchMissionItems()
    this.fetchWhyUsSection()
  }

  fetchAboutSection() {
    this.overviewService.getSection1().subscribe(res => {
      this.about = true
      this.aboutSection = res
    })
  }

  fetchMissionSection() {
    this.overviewService.getSection2().subscribe(res => {
      this.mission = true
      this.missionSection = res
    })
  }
  fetchMissionItems() {
    this.overviewService.getMissionItems(this.missionId).subscribe(res => {
      this.missionItems = []
      res.forEach(a => {
        let item: any = a.payload.doc.data()
        item.id = a.payload.doc.id
        this.missionItems.push(item as HomeItems)
      })
    })
  }

  fetchWhyUsSection() {
    this.overviewService.getSection3().subscribe(res => {
      this.whyUs = true
      this.whyUsSection = res
    })
  }

}
